<template>
  <q-form :id="btnPropsSubmit.form" ref="elQForm" class="f-form" greedy @submit.prevent="onSubmit">
    <div
      class="text-body2 text-accent"
      v-text="t('LPAuctionBid.extra.header', { company: appConfig.company.name })"
    />

    <div class="f-form_grid_columns">
      <FInput
        v-model.trim="formData.contact.firstName"
        :disable="loading"
        :error="!!errors['contact.firstName']?.[0]"
        :error-message="errors['contact.firstName']?.[0]"
        :label="t('LPAuctionBid.fields.firstName.label')"
        lazy-rules
        outlined
        :rules="[requiredDefault]"
      />
      <FInput
        v-model.trim="formData.contact.lastName"
        :disable="loading"
        :error="!!errors['contact.lastName']?.[0]"
        :error-message="errors['contact.lastName']?.[0]"
        :label="t('LPAuctionBid.fields.lastName.label')"
        lazy-rules
        outlined
        :rules="[requiredDefault]"
      />
    </div>

    <FInput
      v-model.trim="formData.contact.email"
      :disable="loading"
      :error="!!errors['contact.email']?.[0]"
      :error-message="errors['contact.email']?.[0]"
      :label="t('LPAuctionBid.fields.email.label')"
      lazy-rules
      outlined
      :rules="[requiredDefault, emailDefault]"
    />

    <FInputPhone
      v-model="formData.contact.phone"
      :disabled="loading"
      :error="!!errors['contact.phone']?.[0]"
      :error-message="errors['contact.phone']?.[0]"
      :label="t('LPAuctionBid.fields.phone.label')"
      outlined
      :rules="[requiredDefault]"
    />

    <div v-if="cans.includes('lp-mortgage')" class="lp-cta-widget-bid-loan-container bg-primary-3">
      <q-checkbox
        v-model="formData.requiresMortgage"
        color="primary"
        keep-color
        :label="t('LPAuctionBid.fields.requiresMortgage.label')"
        size="md"
      />
    </div>

    <FInputTerms v-model="formData.accepts_terms" :error-message="errors.accepts_terms?.[0]" />

    <FInputNewsletterOptIn v-model="formData.newsletterOptIn" color="primary" />

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputNewsletterOptIn from '@/components/Form/input/FInputNewsletterOptIn.vue';
import FInputPhone from '@/components/Form/input/FInputPhone.vue';
import FInputTerms from '@/components/Form/input/FInputTerms.vue';
import { useApiListing } from '@/composables/api/listing';
import { useFormInputRules } from '@/composables/formInputRules';
import { useCaptcha } from '@/composables/useCaptcha';
import useGTM from '@/composables/useGTM';
import { useUtmSource } from '@/composables/useUtmSource';
import { appConfig } from '@/config';
// eslint-disable-next-line import/no-cycle
import { useVarPool } from '@/elr/listing_page/var_pool';
import tFormSteps from '@/i18n/translations/components/formSteps.json';
import useAuthStore from '@/store/modules/auth';
import { useListingStore } from '@/store/modules/listing';
import type { ApiError } from '@/types/api';
import type { StoreAuctionBidVariables } from '@/types/api/listing';
import type { FormId } from '@/types/formStepsFactory';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
}>();

const emit = defineEmits<{
  (e: 'next'): void;
}>();

type Errors = Record<
  | Exclude<keyof typeof formData.value, 'contact'>
  | 'contact.firstName'
  | 'contact.lastName'
  | 'contact.email'
  | 'contact.phone',
  string[]
>;

const { storeAuctionBid } = useApiListing();

const { t } = useI18n(tFormSteps);

const { resolveUtmSource } = useUtmSource();
const { getToken } = useCaptcha();
const { user } = storeToRefs(useAuthStore());
const { isPreview, listing } = storeToRefs(useListingStore());
const { cans } = useVarPool();
const { elQForm, email, required } = useFormInputRules();

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const requiredDefault = required();
const emailDefault = email();

const loading = ref(false);

if (!listing.value) throw new Error('Listing id is undefined');

const formData = ref<StoreAuctionBidVariables>({
  additionalServices: true,
  contact: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  listingId: listing.value.id,
  requiresMortgage: false,
  accepts_terms: false,
  newsletterOptIn: false,
  utmSource: resolveUtmSource(),
  r_token: '',
});

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl',
  color: 'primary',
  disable: loading.value,
  form: `f-${props.formId}`,
  label: t('LPAuctionBid.btn.submit'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const errors = ref<Partial<Errors>>({});

const onSubmit = async () => {
  if (isPreview.value) return;

  errors.value = {};

  const v = await elQForm.value?.validate();
  if (!v) return;

  loading.value = true;

  formData.value.r_token = await getToken('submitAuctionBid');

  storeAuctionBid(formData.value)
    .then(() => {
      useGTM('auction_attend_success');
      emit('next');
    })
    .catch((error: ApiError<keyof typeof formData.value>) => {
      if ('response' in error && error.response) {
        const { response } = error;

        switch (response.status) {
          case 422:
            errors.value = response.data.errors;
            break;
          default:
            break;
        }
      } else {
        // TODO: handle network error
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

watchEffect(() => {
  formData.value.contact.email = user.value?.email || '';
  formData.value.contact.firstName = user.value?.first_name || '';
  formData.value.contact.lastName = user.value?.last_name || '';
  formData.value.contact.phone = user.value?.phone || '';
});
</script>

<style lang="scss">
.lp-cta-widget-bid-loan-container {
  // Checkbox styles following also found in ListingCtaTerms
  // are a suggestion from MG to override quasar styles.
  // TBD
  .q-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .q-checkbox__label {
    padding: 8px 7px 8px 8px;
  }
}
</style>
