import { computed } from 'vue';

import type { FactoryId, FormStepsFactory } from '@/types/formStepsFactory';

export const useStepsLpOffer = () => {
  const factoryId: FactoryId = 'unsupported';

  const getFormStepsLpOffer = (): FormStepsFactory => computed(() => []);

  return { factoryId, getFormStepsLpOffer };
};
