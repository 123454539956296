import { computed } from 'vue';

import type { FactoryId, FormStepsFactory, Step } from '@/types/formStepsFactory';

export const useStepsLpUnlockDocuments = () => {
  const factoryId: FactoryId = 'unsupported';

  const getFormStepsLpUnlockDocuments = (): FormStepsFactory => computed<Step[]>(() => []);

  return { factoryId, getFormStepsLpUnlockDocuments };
};
