import type { AxiosRequestConfig } from 'axios';

import { useApiClient } from '@/composables/api/client';
import type {
  IndexCategoriesResponse,
  IndexCategoriesVariables,
  IndexDocumentsResponse,
  IndexFieldsResponse,
  IndexFieldsVariables,
  IndexOfferTermsResponse,
  IndexPOIsCoordinatesResponse,
  IndexProximityInfoResponse,
  IndexSimilarsResponse,
  IndexTypesResponse,
  IndexTypesVariables,
  ShowIdentificationDetailsResponse,
  ShowRenovationCostResponse,
  ShowRenovationCostsSummaryResponse,
  ShowResponse,
  StoreAuctionBidVariables,
  StoreContactResponse,
  StoreContactVariables,
  StoreInterestVariables,
  StoreMarketabilityReportVariables,
  StoreMortgageVariables,
  StoreOfferVariables,
  StoreOpenDayVariables,
  StoreVariables,
  StoreViewingVariables,
} from '@/types/api/listing';

export const useApiListing = () => {
  const { apiClient } = useApiClient();

  // ????
  const storeMarketabilityReport = (variables: StoreMarketabilityReportVariables) =>
    apiClient.post('lms/marketability-reports', variables);

  const indexCategories = (variables: IndexCategoriesVariables) =>
    apiClient.get<IndexCategoriesResponse>(
      `building-types/groupedByCategory?commercialization_type=${variables}`
    );

  const indexDocuments = (id: number | string) =>
    apiClient.get<IndexDocumentsResponse>(`listings/${id}/documents`);

  const indexFields = (variables: IndexFieldsVariables) =>
    apiClient.get<IndexFieldsResponse>(
      `listing-fields?category=${variables.category}&commercializationType=${variables.cType}&type=${variables.type}`
    );

  const indexOfferTerms = (id: number | string) =>
    apiClient.get<IndexOfferTermsResponse>(`listings/${id}/tender-conditions`);

  const indexPOIsCoordinates = (id: number | string, params?: AxiosRequestConfig['params']) =>
    apiClient.get<IndexPOIsCoordinatesResponse>(`listings/${id}/pois?type=map`, { params });

  const indexProximityInfo = (id: number | string, params?: AxiosRequestConfig['params']) =>
    apiClient.get<IndexProximityInfoResponse>(`listings/${id}/pois?type=proximity`, { params });

  const indexSimilars = (id: number | string) =>
    apiClient.get<IndexSimilarsResponse>(`listings/${id}/similars`);

  const indexTypes = (variables: IndexTypesVariables) =>
    apiClient.get<IndexTypesResponse>(
      `building-types?category=${variables.category}&commercialization_type=${variables.cType}`
    );

  const show = (id: number | string, params?: AxiosRequestConfig['params']) =>
    apiClient.get<ShowResponse>(`listings/${id}`, { params });

  const showIdentificationDetails = (id: number | string) =>
    apiClient.get<ShowIdentificationDetailsResponse>(`listings/${id}/identification-details`);

  const showPreview = (id: number | string, params: AxiosRequestConfig = {}) =>
    apiClient.get<ShowResponse>(`previews/${id}`, params);

  const showRenovationCost = (id: number | string, params?: AxiosRequestConfig['params']) =>
    apiClient.get<ShowRenovationCostResponse>(`listings/${id}/renovation-cost`, { params });

  const showRenovationCostSummary = (id: number | string) =>
    apiClient.get<ShowRenovationCostsSummaryResponse>(`listings/${id}/renovation-costs-summary`);

  const storeAuctionBid = (variables: StoreAuctionBidVariables) =>
    apiClient.post('lms/auction-bids', variables);

  const storeContact = (variables: StoreContactVariables) =>
    apiClient.post<StoreContactResponse>('lms/leads', variables);

  const storeInterest = (variables: StoreInterestVariables) =>
    apiClient.post('lms/interest', variables);

  const store = (variables: StoreVariables) => apiClient.post('lms/assets', variables);

  const storeMortgage = (variables: StoreMortgageVariables) =>
    apiClient.post('lms/mortgages', variables);

  const storeOffer = (variables: StoreOfferVariables) =>
    apiClient.post('lms/offers', variables, { headers: { 'Content-type': 'multipart/form-data' } });

  const storeOpenDay = (variables: StoreOpenDayVariables) =>
    apiClient.post('lms/open-days', variables);

  const storeViewing = (variables: StoreViewingVariables) =>
    apiClient.post('lms/viewings', variables);

  return {
    // ???
    storeMarketabilityReport,

    indexCategories,
    indexDocuments,
    indexFields,
    indexOfferTerms,
    indexPOIsCoordinates,
    indexProximityInfo,
    indexSimilars,
    indexTypes,
    show,
    showIdentificationDetails,
    showPreview,
    showRenovationCost,
    showRenovationCostSummary,
    store,
    storeAuctionBid,
    storeContact,
    storeInterest,
    storeMortgage,
    storeOffer,
    storeOpenDay,
    storeViewing,
  };
};
