<template>
  <div
    v-if="listing"
    :class="{
      listing: true,
      'listing--acquired': cans.includes('acquired'),
    }"
  >
    <div :class="['listing-navbar-sticky', { 'listing-navbar-sticky--visible': isNavbarVisible }]">
      <div class="listing-navbar-sticky__left">
        <div class="listing-title" v-text="listing.title" />

        <div class="navbar__listing-price-container">
          <PriceChangeIndicator
            :price-before="listing.originalPrice"
            :price-current="listing.price"
          />

          <div class="navbar__listing-price">
            <div class="listing-price__text" v-text="priceText" />

            <div
              v-if="squareMeterPriceVisible && !cans.includes('price-upon-request')"
              class="listing-price-per-sqm__text"
              v-text="sqmPriceText"
            />
          </div>
        </div>
      </div>

      <div class="listing-navbar-sticky__right">
        <div class="listing-actions">
          <FavoritesBtn v-bind="qBtnFavoritesProps" />

          <q-separator vertical inset />

          <ShareModalBtn v-bind="qBtnShareModalProps" />

          <template v-if="cans.includes('download-pdf')">
            <q-separator vertical inset />
            <q-btn v-bind="qBtnDownloadPdfProps" />
          </template>

          <q-btn
            v-if="$q.screen.gt.sm && cans.includes('lp-interest')"
            v-bind="qBtnInterestProps"
            @click="onClickDialogFormInterest"
          />
        </div>
      </div>
    </div>

    <AppBreadcrumbs
      v-if="listingMetaData?.breadcrumbs"
      :breadcrumbs="listingMetaData.breadcrumbs"
      class="listing__breadcrumbs"
    />

    <div class="listing__body">
      <div class="listing__body--left">
        <LPMediaCarousel
          :badges="(!cans.includes('acquired') && badges) || []"
          class="listing-media"
          :controls="$q.screen.gt.xs"
          :exclude-controls="['photos', 'areaVideo', 'mapPhotos']"
          :media-all-label="mediaAllLabel"
          ribbon
        />

        <div class="listing-actions">
          <FavoritesBtn v-bind="qBtnFavoritesProps" />

          <q-separator vertical inset />

          <ShareModalBtn v-bind="qBtnShareModalProps" />

          <template v-if="cans.includes('download-pdf')">
            <q-separator vertical inset />
            <q-btn v-bind="qBtnDownloadPdfProps" />
          </template>
        </div>

        <div class="listing-header__container">
          <span class="listing-price__text" v-text="priceText" />

          <span
            v-if="squareMeterPriceVisible && !cans.includes('price-upon-request')"
            class="listing-price-per-sqm__text"
            v-text="sqmPriceText"
          />

          <PriceChangeIndicator
            :price-before="listing.originalPrice"
            :price-current="listing.price"
          />
        </div>

        <div ref="elListingHeaderTitle" class="listing-header__container">
          <span class="listing-title" v-text="listing.title" />

          <span v-if="propertyCodeVisible" class="listing-property-code" v-text="propertyCode" />
        </div>

        <AttributesRenderer :attributes="amenities" class="listing-amenities" />

        <Teleport
          v-if="cans.includes('lp-interest')"
          defer
          :disabled="$q.screen.gt.sm"
          to=".layout-main-footer__body"
        >
          <q-btn v-bind="qBtnInterestProps" @click="onClickDialogFormInterest" />
        </Teleport>

        <q-separator class="listing__body-separator--last" />

        <div class="listing__body--container">
          <span class="listing-body__text--header" v-text="tListingPage('section.details.title')" />

          <div class="listing-description">
            <span
              class="listing__body--title"
              v-text="tListingPage('section.details.description')"
            />
            <span
              v-dompurify-html="listing.listingInfo.description"
              class="listing-description__value"
            />
          </div>

          <LPCategoryAttributes />

          <div v-if="!!listing.listingInfo.disclaimer?.length">
            <div
              v-for="(disclaimer, i) in listing.listingInfo.disclaimer"
              :key="i"
              v-dompurify-html="`*${disclaimer}`"
              class="listing-disclaimer__text"
            />
          </div>

          <LPUnlockDocuments v-if="cans.includes('documents')" />
        </div>
      </div>

      <div class="listing__body--right">
        <component
          :is="c"
          v-for="(c, i) in locationInfoBlockComponents"
          :key="i"
          class="listing__block listing__block--location"
        />

        <div v-if="cans.includes('lp-mortgage')" class="listing-mortgage">
          <q-icon class="listing-mortgage__icon" name="calculator" />

          <div class="listing-mortgage__text">
            <div class="listing__body--title" v-text="tListingPage('section.mortgage.title')" />
            <div
              class="listing-mortgage__text--subtitle"
              v-text="tListingPage('section.mortgage.subtitle')"
            />
          </div>

          <q-btn
            class="listing__btn--mortgage"
            :label="tListingPage('btn.mortgage.label')"
            :href="externalUrl"
            no-caps
            no-wrap
            outline
            rel="noopener noreferrer"
            target="_blank"
            type="a"
          />
        </div>
      </div>

      <DialogFormStepper
        v-if="dialogFormFactoryId"
        v-model="dialog"
        :factory-id="dialogFormFactoryId"
        persistent
        :steps="dialogFormSteps"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { Screen } from 'quasar';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import AppBreadcrumbs from '@/components/AppCommon/AppBreadcrumbs.vue';
import AttributesRenderer from '@/components/AttributesRenderer.vue';
import DialogFormStepper from '@/components/Dialog/DialogFormStepper.vue';
import FavoritesBtn from '@/components/Global/FavoritesBtn.vue';
import LPAddress from '@/components/ListingPage/fragments/LPAddress.vue';
import LPCategoryAttributes from '@/components/ListingPage/fragments/LPCategoryAttributes.vue';
import LPMediaCarousel from '@/components/ListingPage/fragments/LPMediaCarousel.vue';
import LPUnlockDocuments from '@/components/ListingPage/fragments/LPUnlockDocuments.vue';
import LPPoisMap from '@/components/ListingPage/Pois/LPPoisMap.vue';
import LPPoisProximity from '@/components/ListingPage/Pois/LPPoisProximity.vue';
import PriceChangeIndicator from '@/components/Reusable/PriceChangeIndicator.vue';
import ShareModalBtn from '@/components/Reusable/ShareModalBtn.vue';
import { useFeatureConfig } from '@/composables/featureConfig';
import { useTheme } from '@/composables/theme';
import { useFavorites } from '@/composables/useFavorites';
import { appConfig } from '@/config';
import { useVarPool } from '@/elr/listing_page/var_pool';
import { useStepsLpInterest } from '@/factories/formStepsFactory/lpInterest';
import translations from '@/i18n/translations/components/listingPage.json';
import { useListingStore } from '@/store/modules/listing';
import { formStepperKey, type PayloadFormStepper } from '@/types/event-bus';
import type { FactoryId, Step } from '@/types/formStepsFactory';

const { getFeature } = useFeatureConfig();

const listingStore = useListingStore();
const { amenities, downloadPdfUrl, isPreview, listing, listingMetaData } =
  storeToRefs(listingStore);

const { isFavorite } = useFavorites();
const { badges, cans } = useVarPool();
const bus = useEventBus(formStepperKey);
const { t: tListingPage } = useI18n(translations);
const { t: tGlobal } = useI18n();
const { formatPrice, propertyCodePrefix, propertyCodeVisible } = useTheme();

const propertyCode = computed(() => {
  let propertyCodeLocal = `${tGlobal(propertyCodePrefix)}: ${listing.value?.propertyCode}`;

  const groupPropertyParentCode = listing.value?.groupPropertyParentCode;

  if (groupPropertyParentCode) {
    propertyCodeLocal += ` (${groupPropertyParentCode})`;
  }

  return propertyCodeLocal;
});

const { squareMeterPriceVisible } = appConfig;

const isMounted = ref(false);

const loadingInterest = ref(false);
const submittedInterest = ref(false);

const isNavbarVisible = ref(false);
const elListingHeaderTitle = useTemplateRef<HTMLDivElement>('elListingHeaderTitle');

const { factoryId: factoryIdLpInterest, getFormStepsLpInterest } = useStepsLpInterest();
const formStepsLpInterest = getFormStepsLpInterest();

const dialog = ref(false);
const dialogFormFactoryId = ref<FactoryId>();
const dialogFormSteps = ref<Step[]>([]);

const onClickDialogFormStepper = (factoryId: FactoryId, steps: Step[]) => {
  dialogFormFactoryId.value = factoryId;
  dialogFormSteps.value = steps;

  nextTick(() => {
    dialog.value = true;
  });
};

const onClickDialogFormInterest = () => {
  if (isPreview.value) return;

  loadingInterest.value = true;

  onClickDialogFormStepper(factoryIdLpInterest, formStepsLpInterest.value);
};

const externalUrl = computed(() => getFeature('loanRequest')?.options?.external_url);

const locationInfoBlockComponents = computed(() => {
  if (!cans.value.includes('location')) return [];

  const configLocationInfo = getFeature('locationInfo')?.options;

  if (!configLocationInfo?.blocks) return [];

  return configLocationInfo.blocks.map(b => {
    switch (b) {
      case 'address':
        return LPAddress;
      case 'map':
        return LPPoisMap;
      case 'proximities':
        return LPPoisProximity;
      default:
        throw new Error(`Unsupported block type: ${b}`);
    }
  });
});

const photosMerged = computed(() => [
  ...(listing.value?.images.photos || []),
  ...(listing.value?.images.mapPhotos || []),
]);

const mediaAllLabel = computed(() =>
  Screen.lt.md ? tListingPage('section.media.controls.all') : photosMerged.value.length.toString()
);

watch(
  () => Screen.gt.sm,
  v => {
    if (v) {
      dialog.value = false;
    }
  }
);

const busListener = (e: PayloadFormStepper) => {
  if (e.factoryId === 'lp-interest') {
    switch (e.event) {
      case 'abort':
        loadingInterest.value = false;
        break;
      case 'complete':
        loadingInterest.value = false;
        submittedInterest.value = true;
        break;
      default:
        break;
    }
  }
};

const priceText = computed(() =>
  cans.value.includes('price-upon-request')
    ? tListingPage('section.details.priceUponRequest')
    : formatPrice(listing.value!.price)
);

const sqmPriceText = computed(() => `${formatPrice(listing.value!.squareMetrePrice)} / m²`);

const qBtnInterestProps = computed(() => ({
  class: 'listing__btn--interest',
  disable: submittedInterest.value || loadingInterest.value,
  icon: submittedInterest.value ? 'check' : undefined,
  label: tListingPage(`btn.interest.${submittedInterest.value ? 'submitted' : 'submit'}`),
  noCaps: true,
  noWrap: true,
  outline: true,
  unelevated: true,
}));

const qBtnDownloadPdfProps = computed(() => ({
  class: 'listing-actions__btn',
  disable: isPreview.value,
  href: downloadPdfUrl.value,
  icon: 'download',
  label: tListingPage('btn.downloadPdf.label'),
  noCaps: true,
  noWrap: true,
  outline: false,
  ripple: false,
  type: 'a',
  unelevated: true,
}));

const qBtnShareModalProps = computed(() => ({
  class: 'listing-actions__btn',
  disable: isPreview.value,
  qBtnProps: {
    icon: 'share',
    label: tListingPage('btn.share.label'),
  },
  ripple: false,
}));

const qBtnFavoritesProps = computed(() => ({
  class: 'listing-actions__btn',
  disable: isPreview.value,
  icon: isFavorite(listing.value!.id) ? 'heart' : 'heartSpace',
  label: tListingPage(`btn.favorites.${isFavorite(listing.value!.id) ? 'remove' : 'add'}`),
  listingId: listing.value!.id,
  ripple: false,
}));

bus.on(busListener);

const handleScroll = () => {
  if (elListingHeaderTitle.value) {
    const targetPosition = elListingHeaderTitle.value.getBoundingClientRect();
    const targetBottom = targetPosition.bottom;

    isNavbarVisible.value = targetBottom <= 0;
  }
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  isMounted.value = true;
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
  bus.off(busListener);
});
</script>
