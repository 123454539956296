import { APP_VERSION } from '@/config/appEnvs';
/**
 * WE MUST ALIASES HERE STO THAT WE DO NOT DUPLICATE ICON ENTRIES
 */

const customerLogoIcons = {
  altia: 'altia',
  dovalue: 'dovalue',
  iauction: 'iauction',
  intrum: 'intrum',
  prosperty: 'prosperty',
  southrock: 'southrock',
};

const customerPanelIcons = {
  empty_offers: 'empty_offers',
  empty_visits: 'empty_visits',
};

const offerIcons = {
  goodFace: 'goodFace',
  mediumFace: 'mediumFace',
  badFace: 'badFace',
};

const auctionIcons = {
  auction_date: 'auctiondate',
  auction_round: 'auction_round',
  auction_round_filled: 'auction_round_filled',
};

const mapIcons = {
  map: 'map',
  mapMarker: 'mapMarker',
  marker: 'marker',
  chooseOnMap: 'chooseOnMap',
  priceUponRequest: 'priceUponRequest',
  mapZoomIn: 'mapZoomIn',
  mapZoomOut: 'mapZoomOut',
  streetView: 'streetView',
  layerView: 'layerView',
};

const mapPoiIcons = {
  largerMap: 'largerMap',
  school: 'school',
  supermarket: 'supermarket',
  restaurant: 'restaurant',
  hospital: 'hospital',
  entertainment: 'entertainment',
  directions: 'directions',
};

const listingProximityIcons = {
  airport: 'airport',
  beach: 'sea',
  subway_station: 'subway_station',
  driving: 'driving',
  park: 'park',
  walking: 'walking',
  port: 'port',
};

const listingExtraInfoIcons = {
  general: 'general',
  building: 'building',
  interior: 'interior',
  plot: 'plot',
};

const listingMediaIcons = {
  floorPlan: 'floorPlan',
  media: 'media',
  drone: 'drone',
  tour: 'tour',
  play: 'play',
  photoGallery: 'photoGallery',
  photoEnvelope: 'photoEnvelope',
};

const listingBadgeIcons = {
  documentCheckMark: 'documentCheckMark',
  underDevelopment: 'underDevelopment',
  ownership: 'ownership',
  thunderbolt: 'thunderbolt',
  exclusive: 'exclusive',
  hot: 'hot',
  vip: 'vip',
};

const listingAggregateIcons = {
  hotelBed: 'hotelBed',
  hotelRooms: 'hotelRooms',
  floor: 'floor',
  size: 'size',
  parking: 'parking',
  paintRoller: 'paintRoller',
  bedroom: 'bedrooms',
  bedrooms: 'bedrooms',
  buildYear: 'buildYear',
};

const interactIcons = {
  arrow_large_down: 'arrow_large_down',
  arrow_down: 'arrow_down',
  arrow_drop_down: 'arrow_drop_down',
  arrow_left: 'arrow_left',
  arrow_right: 'arrow_right',
  arrow_up: 'arrow_up',
  cancel: 'cancel',
  download: 'download',
  copy: 'copy',
  chevron_left: 'arrowLeft',
  chevron_right: 'arrowRight',
  email: 'email',
  keyboard_arrow_up: 'keyboard_arrow_up',
  keyboard_arrow_left: 'arrowLeft',
  keyboard_arrow_right: 'arrowRight',
  export: 'export',
  unlock: 'unlock',
  last_page: 'arrowButtonRight',
  first_page: 'arrowButtonLeft',
  share: 'share',
  star: 'star',
};

const menuIcons = {
  menu: 'menu',
  careers: 'careers',
  profile: 'profile',
  calendar: 'calendar',
  contact: 'contact',
  myOffers: 'myoffers',
  settings: 'settings',
  phone: 'phone',
};

const iconSet = {
  ...auctionIcons,
  ...customerLogoIcons,
  ...customerPanelIcons,
  ...offerIcons,
  ...mapIcons,
  ...mapPoiIcons,
  ...menuIcons,
  ...listingAggregateIcons,
  ...listingBadgeIcons,
  ...listingExtraInfoIcons,
  ...listingProximityIcons,
  ...listingMediaIcons,
  ...interactIcons,
  ac: 'ac',
  attachment: 'attachment',
  autonomy: 'autonomy',
  bathroom: 'bathroom',
  bright: 'bright',
  check: 'check',
  calculator: 'calculator',
  city: 'city',
  commercial: 'commercial',
  commercialOpportunity: 'residentialOpportunity',
  coastalOpportunity: 'coastalOpportunity',
  diamond: 'diamond',
  developmentOpportunity: 'developmentOpportunity',
  documentHouse: 'documentHouse',
  ecofriendly: 'ecofriendly',
  evCharger: 'evCharger',
  filter: 'filter',
  filterHorizontal: 'filterHorizontal',
  financial: 'financial',
  fireplace: 'fireplace',
  garden: 'garden',
  gas: 'gas',
  green: 'green',
  house: 'house',
  house_2: 'house_2',
  info: 'info',
  lake: 'lake',
  land: 'land',
  landOpportunity: 'residentialOpportunity',
  list: 'list',
  luxurious: 'luxurious',
  monument: 'monument',
  mountain: 'mountain',
  noresults: 'noresults',
  other: 'other',
  panoramic: 'panoramic',
  parkingSpace: 'parking',
  pool: 'pool',
  price: 'price',
  residential: 'residential',
  residentialOpportunity: 'residentialOpportunity',
  sea: 'sea',
  search: 'search',
  type: 'type',
  unit: 'unit',
  viewing: 'viewing',
  views: 'views',
  wideBalcony: 'wideBalcony',
  documentReady: 'documentReady',
  offer: 'offer',
  storage: 'storage',
  success: 'success',
  successOutline: 'successOutline',
  visits: 'visits',
  myListings: 'mylistings',
  sell: 'sell',
  renovation: 'renovation',
  rent: 'rent',
  address: 'address',
  agent: 'agent',
  event: 'event',
  buildingFactor: 'buildingFactor',
  coverageFactor: 'coverageFactor',
  forStudents: 'forStudents',
  forInvestment: 'forInvestment',
  openPlan: 'openPlan',
  fiberOptics: 'fiberOptics',
  utpCabling: 'fiberOptics',
  industrialElevator: 'industrialElevator',
  elevator: 'elevator',
  topFloor: 'floor',
  warning: 'warning',
  lens: 'lens',
  empty_favorites: 'empty_favorites',
  heart: 'heart',
  heartSpace: 'heartSpace',
  trashCan: 'trashCan',
  refresh: 'refresh',
  wavesStair: 'wavesStair',
  window: 'window',
  housePlan: 'housePlan',
};

const generateSVGReference = (iconValue: string, version: string) =>
  `svguse:/icons/icons.svg?${version}#${iconValue}`;

const svgReferences: Partial<
  Record<keyof typeof iconSet, ReturnType<typeof generateSVGReference>>
> = {};

Object.keys(iconSet).forEach(iconName => {
  const key = iconName as keyof typeof iconSet;

  svgReferences[key] = generateSVGReference(iconSet[key], APP_VERSION);
});

export default svgReferences;
